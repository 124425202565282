import * as React from "react"
import { Helmet } from "react-helmet"
import { globalHistory } from "@reach/router"

export default function ArticleSeoHelmet({ gravatar, profileData, title }) {
    const lang = profileData.node_locale;
    const url = globalHistory.location.href;

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={`${profileData.name} - ${title}`}
            titleTemplate={title + " - " + profileData.name}
            meta={[
                { name: 'title', content: title + " - " + profileData.name },
                { name: 'description', content: "description" },
                // OpenGraph meta for Facebook
                { property: "og:type", content: "website" },
                { property: "og:url", content: url },
                { property: "og:title", content: title + " - " + profileData.name },
                { property: "og:description", content: "description" },
                { property: "og:image", content: gravatar.url },
                // Twitter metas
                { property: "twitter:card", content: "summary_large_image" },
                { property: "twitter:url", content: url },
                { property: "twitter:title", content: title + " - " + profileData.name },
                { property: "twitter:description", content: "description" },
                { property: "twitter:image", content: gravatar.url }

            ]}
        />
    )
}